<template>
  <div class="font">
    <ayl-berad-nav :nav="nav" />
    <div class="content-box mt20px">
      <div class="content-main">
        <ayl-table-with-query-object :table="table" @selection-change="selectionTable">
          <div slot="ctrl-button">
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <el-button class="ml40px" type="info" style="float: right;" @click="onDelete" plain>删除</el-button>
            <el-button class="ml40px" type="info" style="float: right;" plain @click="onSubmitTo">提交</el-button>
          </div>
        </ayl-table-with-query-object>
      </div>
    </div>
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
      :width="widthForDialog"
    ></ayl-dialog>
  </div>
</template>

<script>
import Delete from "@/mixins/delete";
import SubmitTo from "@/mixins/submit-to";
export default {
  mixins: [Delete, SubmitTo],
  data() {
    // eslint-disable-next-line no-unused-vars
    const vm = this;
    return {
      nav: [{ name: "安全培训记录" }],
      // 弹窗参数
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,
      widthForDialog: "50%",

      //删除参数
      deleteAPI_Id: [
        this.$api_hw.operationSafetyManagement_safetyTrainingDelete,
        "idList",
        "trainingId",
        "table"
      ],
      // 提交到城管局
      submitToData: [
        this.$api_hw.operationSafetyManagement_safetyTrainingCommit,
        "idList",
        "trainingId",
        "table"
      ],

      common_getBidNameAndIdList: [true, 0, null], //标段管理下拉
      districtInfo_getDistinctCareUnit: [true, 1, null], // 养护企业信息下拉
      common_getDistinctManageUnit: [true, 2, null], //获取主管单位下拉数据

      table: {
        api:
          vm.$api_hw.operationSafetyManagement_safetyTrainingListCompanyObject,
        query: {
          queryCondition: {
            bidId: null, //标段
            careUnitName: null, //养护企业
            bidManageName: null, //主管单位
            startTime: null, //开始时间
            endTime: null, //结束时间
            trainingType: null //培训类型
          }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "queryCondition.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "养护企业",
            model: "queryCondition.careUnitName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "主管单位",
            model: "queryCondition.bidManageName",
            placeholder: "请选择",
            option: []
          },
          {
            type: "dateSection",
            title: "培训日期",
            isOptions: true,
            model: [],
            modelName: ["queryCondition.startTime", "queryCondition.endTime"]
          },
          {
            type: "select",
            title: "培训类型",
            model: "queryCondition.trainingType",
            placeholder: "请选择",
            option: this.$enums_hw.trainingTypeOption.list
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段名称",
            key: "bidName"
            // width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "careUnitName"
          },
          {
            title: "主管单位",
            showTooltip: true,
            key: "bidManageUnit"
          },
          {
            title: "培训时间",
            key: "trainingTime",
            filter: "str2ymd",
            width: "170px"
          },
          {
            title: "培训类型",
            key: "trainingTypeDes"
          },
          {
            title: "参与人数",
            key: "participantNum"
          },
          {
            title: "主持人",
            key: "host"
          },
          {
            title: "备注",
            key: "remark"
          },
          {
            title: "状态",
            key: "checkStatusDes"
          },
          {
            title: "审核意见",
            key: "checkResult"
          },
          {
            title: "操作",
            align: "center",
            width: "120px",
            render(h, ctx) {
              return h("div", [
                h(
                  "el-button",
                  {
                    style: {
                      color: "#1ABC9C"
                    },
                    props: {
                      type: "text"
                    },
                    on: {
                      click: vm.onInfo.bind(this, ctx.row)
                    }
                  },
                  "查看"
                ),
                h(
                  "el-button",
                  {
                    style: {
                      color:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? ""
                          : "#1ABC9C"
                    },
                    props: {
                      type: "text",
                      disabled:
                        ctx.row.checkStatus === 1 || ctx.row.checkStatus === 2
                          ? true
                          : false
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      }
    };
  },

  methods: {
    //新增
    onAdd() {
      //传views的url
      this.viewUrlForDialog =
        "/operation-safety-management/safety-training-record/add";
      //传参给弹窗
      this.widthForDialog = "50%";
      this.dataForDialog = {};
      this.isShowDialog = true; //显示弹窗
    },

    //查看
    onInfo(val) {
      this.viewUrlForDialog =
        "/operation-safety-management/safety-training-record/info";
      //传参给弹窗
      this.widthForDialog = "50%";
      this.dataForDialog = {
        id: val.trainingId
      };
      this.isShowDialog = true; //显示弹窗
    },
    //编辑
    onEdit(val) {
      this.viewUrlForDialog =
        "/operation-safety-management/safety-training-record/edit";
      //传参给弹窗
      this.widthForDialog = "50%";
      this.dataForDialog = {
        id: val.trainingId
      };
      this.isShowDialog = true; //显示弹窗
    }
  },
  async mounted() {
    BUS.$on(BUSEVENT.REFRESH_SAFETT_TRAINING_RECORD, () => {
      this.$search(this.table);
    });
  }
};
</script>
<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
/deep/ .el-button--primary 
  color: #FFFFFF
  background-color: #1ABC9C
  border-color: #1ABC9C

.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>